
import { Component, Vue } from 'vue-property-decorator'
import { DiseaseAddInfo } from '@/types/pestControl'
import { FileInfo } from '@/types/common'

@Component({
  name: 'diseaseDetail',
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  private detailInfo: DiseaseAddInfo = {
    diseasesName: '',
    happenArea: '',
    diseasesOtherName: '',
    harmPlants: '',
    diseasesType: '',
    etiologyType: '',
    medicineKind: '',
    harmPart: '',
    happenTime: '',
    resourceList: [],
    describes: ''
  }

  get diseasesId () {
    return this.$route.params.diseasesId || ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.pestControl.selectYhDiseasesByDiseasesId, {
      diseasesId: this.diseasesId
    }).then(res => {
      this.detailInfo = res || {}
    })
  }
}
